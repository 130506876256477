export const CREATE_PERMISSION = "module/create_permission";
export const GET_PERMISSION = "get-permission";
export const DELETE_PERMISSION = "module/delete_permission";
export const NAME_OF_PERMISSION_SLICE = "managePermissionSlice";

export const NAME_OF_MODULE_SLICE = "manageModuleSlice";
export const GET_MODULE_WITH_PERMISSION = "get-module-with-permission";

export const NAME_OF_MODULE_GROUP_SLICE = "manageModuleGroupSlice";
export const CREATE_MODULE_GROUP = "module/create_moduel_group";
export const SHOW_MODULE = "module/show_module";

export const CREATE_ROLE = "module/create_role";
export const GET_ROLE = "module/get_role";
export const DELETE_ROLE = "module/delete_role";
export const NAME_OF_ROLE_SLICE = "manageRoleSlice";
export const GET_ORGANIZATION_ROLES = "get-organization-roles";

//socket key for shake-hand with server-end application
export const REACT_APP_PUSHER_APP_KEY = "pusher_id";
export const REACT_APP_PUSHER_CLUSTER = "mt1";

export const NAME_OF_SEARCH_SLICE = "module/search";
export const APPLE_DATA_API_URL = "apple-data";
export const APPLE_DATA = "manageAppleDataSlice";

export const MANAGE_USER_API_URL = "manage-user";
export const MANAGE_USER_FILTER_API_URL = "manage-user-filter";
export const MANAGE_USER = "manageManageUserSlice";

export const MANAGE_LOOKUPS_API_URL = "manage-lookups";
export const MANAGE_LOOKUPS = "manageManageLookupsSlice";
export const SETUP_ORGANIZATION_API_URL = "setup-organization";
export const SETUP_ORGANIZATION = "manageSetupOrganizationSlice";

export const ORGANIZATION_API_URL = "organization"; //TODO:: make it organizations for better understand
export const GET_ALL_ORGANIZATION_API_URL = "get-all-organization"; //TODO:: make it organizations for better understand

export const ORGANIZATION = "manageOrganizationSlice";
export const GET_LOGIN_USER_ORGANIZATIONS = "get-login-user-organizations";
export const GET_ORGANIZATION_ROLE = "get-organization-role";

export const ROLE_PERMISSION_API_URL = "role-permission";
export const GET_ALL_ROLE_PERMISSION_API_URL = "get-all-role-permission";
export const GET_EXCLUDE_ORGANIZATION_ROLE_URL =
  "get-exclude-organization-role";
export const ROLE_PERMISSION = "manageRolePermissionSlice";

export const MANAGE_TEACHERS_API_URL = "manage-teachers";
export const MANAGE_TEACHERS = "manageManageTeacherSlice";
export const GET_ALL_MANAGE_TEACHERS_API_URL = "get-all-manage-teachers";

export const SCHOOL_TYPES_API_URL = "school-types";
export const GET_ALL_SCHOOL_TYPES_API_URL = "get-all-school-types";
export const SCHOOL_TYPES = "manageSchoolTypeSlice";

export const ORGANIZATION_TYPES_API_URL = "organization-types";
export const GET_ALL_ORGANIZATION_TYPES_API_URL = "get-all-organization-types";
export const ORGANIZATION_TYPES = "manageOrganizationTypeSlice";

export const COUNTRY_API_URL = "country";
export const GET_ALL_COUNTRY_API_URL = "get-all-country";
export const COUNTRY = "manageCountrySlice";

export const CITY_API_URL = "city";
export const GET_ALL_CITY_API_URL = "get-all-city";
export const CITY = "manageCitySlice";

export const TEACHER_ROLE_API_URL = "teacher-role";
export const GET_ALL_TEACHER_ROLE_API_URL = "get-all-teacher-role";
export const TEACHER_ROLE = "manageTeacherRoleSlice";

export const SCHOOL_CATEGORY_API_URL = "school-category";
export const GET_ALL_SCHOOL_CATEGORY_API_URL = "get-all-school-category";
export const SCHOOL_CATEGORY = "manageSchoolCategorySlice";

export const GRADE_LEVEL_API_URL = "grade-level";
export const GET_ALL_GRADE_LEVEL_API_URL = "get-all-grade-level";
export const UPDATE_GRADE_SORT_API_URL = "update-grade-sort";
export const GRADE_LEVEL = "manageGradeLevelSlice";

export const SCHOOL_YEAR_API_URL = "school-year";
export const GET_ALL_SCHOOL_YEAR_API_URL = "get-all-school-year";
export const GET_SET_DEFAULT_YEAR_API_URL = "set-default-year";
export const SCHOOL_YEAR = "manageSchoolYearSlice";

export const RELATIONSHIP_API_URL = "relationship";
export const GET_ALL_RELATIONSHIP_API_URL = "get-all-relationship";
export const RELATIONSHIP = "manageRelationshipSlice";

export const PROVINCE_API_URL = "province";
export const GET_ALL_PROVINCE_API_URL = "get-all-province";
export const PROVINCE = "manageProvinceSlice";

export const THEME_SETTING_API_URL = "theme-setting";
export const GET_ALL_THEME_SETTING_API_URL = "get-all-theme-setting";
export const THEME_SETTING = "manageThemeSettingSlice";

export const ADDRESS_TYPE_API_URL = "address-type";
export const GET_ALL_ADDRESS_TYPE_API_URL = "get-all-address-type";
export const ADDRESS_TYPE = "manageAddressTypeSlice";

export const NATIONALITY_API_URL = "nationality";
export const GET_ALL_NATIONALITY_API_URL = "get-all-nationality";
export const NATIONALITY = "manageNationalitySlice";

export const ALLERGIES_API_URL = "allergies";
export const GET_ALL_ALLERGIES_API_URL = "get-all-allergies";
export const ALLERGIES = "manageAllergiesSlice";

export const SALUTATION_API_URL = "salutation";
export const GET_ALL_SALUTATION_API_URL = "get-all-salutation";
export const SALUTATION = "manageSalutationSlice";

export const ENROLLMENT_STATUS_API_URL = "enrollment-status";
export const GET_ALL_ENROLLMENT_STATUS_API_URL = "get-all-enrollment-status";
export const ENROLLMENT_STATUS = "manageEnrollmentStatusSlice";

export const IDENTIFICATION_TYPE_API_URL = "identification-type";
export const GET_ALL_IDENTIFICATION_TYPE_API_URL =
  "get-all-identification-type";
export const IDENTIFICATION_TYPE = "manageIdentificationTypeSlice";

export const SCHOOL_ROOM_API_URL = "school-room";
export const GET_ALL_SCHOOL_ROOM_API_URL = "get-all-school-room";
export const SCHOOL_ROOM = "manageSchoolRoomSlice";

export const GRADE_API_URL = "grade";
export const GET_ALL_GRADE_API_URL = "get-all-grade";
export const GRADE = "manageGradeSlice";
export const STUDENT_GRADE_BOOK_SLICE = "studentGradeBookSlice";

export const GRADE_BOOK_CATEGORY_API_URL = "grade-book-category";
export const GET_ALL_GRADE_BOOK_CATEGORY_API_URL =
  "get-all-grade-book-category";
export const GRADE_BOOK_CATEGORY = "manageGradeBookCategorySlice";

export const SCHOOL_TERM_API_URL = "school-term";
export const GET_ALL_SCHOOL_TERM_API_URL = "get-all-school-term";
export const SCHOOL_TERM = "manageSchoolTermSlice";

export const COURSE_API_URL = "course";
export const GET_ALL_COURSE_API_URL = "get-all-course";
export const COURSE = "manageCourseSlice";

export const CLASS_API_URL = "class";
export const GET_ALL_CLASS_API_URL = "get-all-class";
export const CLASS = "manageClassSlice";

export const MANAGE_CLASS_API_URL = "manage-class";
export const ADD_CLASS_COURSE_API_URL = "add-class-course";
export const ADD_CLASS_RESOURCE_API_URL = "add-course-resource";
export const ADD_CLASS_COURSE_WEIGHTAGE_API_URL = "add-class-course-weightage";
export const ADD_CLASS_STUDENT_API_URL = "add-class-student";
export const ADD_CLASS_COURSE_SCHEDULE_ACTIVITY_API_URL =
  "add-class-course-schedule-activity";
export const GET_OTHER_CLASS_API_URL = "get-other-classes";
export const GET_GRADE_STUDENT_API_URL = "get-grade-students";
export const GET_OTHER_CLASS_STUDENT_API_URL = "get-other-class-students";
export const DELETE_CLASS_STUDENT_API_URL = "delete-class-student";
export const DELETE_CLASS_COURSE_API_URL = "delete-class-course";
export const DELETE_CLASS_COURSE_Teacher_API_URL =
  "delete-class-course-teacher";
export const DELETE_CLASS_RESOURCE_API_URL = "delete-class-resource";
export const CLASS_COURSE_FILTER_API_URL = "class-course-filter";
export const CLASS_STUDENT_FILTER_API_URL = "class-student-filter";
export const GET_ALL_MANAGE_CLASS_API_URL = "get-all-manage-class";
export const GET_CLASS_COURSE_API_URL = "get-class-courses";
export const SAVE_LESSON_TOPIC = "save-lesson-topic";
export const GET_TAGGED_CLASS_COURSE_TEACHER_API_URL =
  "get-tagged-class-course-teachers";
export const GET_TAGGED_CLASS_RESOURCES_API_URL = "get-tagged-class-resources";
export const GET_CLASS_COURSE_DATA_API_URL = "get-class-course-data";
export const MANAGE_CLASS = "manageManageClassSlice";
export const GET_CLASS_COURSE_STRANDS_API_URL = "get-class-course-strands";
export const GET_STRANDS_COURSES_API_URL = "get-strands-courses";
export const GET_CLASS_COURSE_WITHOUT_STRAND_API_URL = "get-class-course-without-strand";
export const SAVE_CLASS_COURSES_STRANDS_API_URL = "save-class-courses-strands";
export const ADD_COURSE_STRANDS_API_URL = "add-class-course-strands";
export const UPDATE_COURSE_STRANDS_SORT_API_URL = "update-class-course-strands-sort";
export const DELETE_COURSE_STRANDS_API_URL = "delete-class-course-strands";

export const ATTENDANCE_MODIFICATION_API_URL = "attendance-modification";
export const GET_ALL_ATTENDANCE_MODIFICATION_API_URL =
  "get-all-attendance-modification";
export const ATTENDANCE_MODIFICATION = "manageAttendanceModificationSlice";

export const ABSENT_REASON_API_URL = "absent-reason";
export const GET_ALL_ABSENT_REASON_API_URL = "get-all-absent-reason";
export const ABSENT_REASON = "manageAbsentReasonSlice";

export const LEAVE_REQUEST_API_URL = "leave-request";
export const GET_ALL_LEAVE_REQUEST_API_URL = "get-all-leave-request";
export const GET_ATTENDANCE_CODE_API_URL = "get-attendance-codes-for-leave";
export const LEAVE_REQUEST = "manageLeaveRequestSlice";

export const MANAGE_ROUTES_API_URL = "routes";
export const GET_ALL_MANAGE_ROUTES_API_URL = "get-all-manage-routes";
export const MANAGE_ROUTES = "manageManageRoutesSlice";

export const MANAGE_BUSES_API_URL = "manage-buses";
export const GET_ALL_MANAGE_BUSES_API_URL = "get-all-manage-buses";
export const MANAGE_BUSES = "manageManageBusesSlice";

export const MANAGE_TRANSPORT_API_URL = "manage-transport";
export const GET_ALL_MANAGE_TRANSPORT_API_URL = "get-all-manage-transport";
export const GET_ALL_ROUTE_BUS_STUDENTS_URL = "get-all-route-bus-students";
export const GET_BUS_STUDENT_EDIT_DATA_URL = "/get-bus-students-edit-data";
export const DELETE_BUS_STUDENT_URL = "/delete-bus-student";
export const MANAGE_TRANSPORT = "manageManageTransportSlice";

export const SEND_NOTIFICATION_API_URL = "send-notification";
export const GET_ALL_SEND_NOTIFICATION_API_URL = "get-all-send-notification";
export const SEND_NOTIFICATION = "manageSendNotificationSlice";

export const CHARGES_CATEGORIES_API_URL = "charges-categories";
export const GET_ALL_CHARGES_CATEGORIES_API_URL = "get-all-charges-categories";
export const CHARGES_CATEGORIES = "manageChargesCategoriesSlice";

export const CHARGES_TYPES_API_URL = "charges-types";
export const GET_ALL_CHARGES_TYPES_API_URL = "get-all-charges-types";
export const CHARGES_TYPES = "manageChargesTypesSlice";

export const CHARGES_RATES_API_URL = "charges-rates";
export const GET_ALL_CHARGES_RATES_API_URL = "get-all-charges-rates";
export const GET_CHARGE_RATE_EXIST_API_URL = "get-charge-rate-exist";
export const CHARGES_RATES = "manageChargesRatesSlice";

export const RECURRING_CHARGES_API_URL = "recurring-charges";
export const GET_ALL_RECURRING_CHARGES_API_URL = "get-all-recurring-charges";
export const GET_ALL_GRADE_LEVEL_STUDENT_API_URL =
  "get-all-grade-level-student";
export const GET_GRADE_LEVEL_STUDENT_API_URL = "get-grade-level-students";
export const APPLY_SIBLING_DISCOUNT_API_URL = "apply-sibling-discount";
export const RECURRING_CHARGES = "manageRecurringChargesSlice";

export const STUDENT_CHARGES_API_URL = "student-charges";
export const SINGLE_STUDENT_CHARGES_API_URL = "single-student-charges";
export const GET_ALL_STUDENT_CHARGES_API_URL = "get-all-student-charges";
export const STUDENT_CHARGES = "manageStudentChargesSlice";
export const STUDENT_CHARGES_OVERVIEW_API_URL = "student-charges-overview";
export const APPLY_TRANSPORT_FEE_API_URL = "import-bus-transport-fee";
export const STUDENT_CHARGES_BREAKDOWN_API_URL = "student-charges-breakdown";

export const INVOICES_API_URL = "invoices";
export const GET_ALL_INVOICES_API_URL = "get-all-invoices";
export const GET_CHARGE_TYPE_INVOICE_URL = "charge-type-invoices";
export const GET_DETAIL_CHARGE_TYPE_INVOICE_URL = "detail-charge-type-invoices";
export const GET_MONTHLY_WISE_INVOICE_URL = "monthly-wise-invoices";
export const GET_DETAIL_MONTHLY_WISE_INVOICE_URL =
  "detail-monthly-wise-invoices";
export const GET_STUDENT_WISE_INVOICE_URL = "student-wise-invoices";
export const GET_DETAIL_STUDENT_WISE_INVOICE_URL =
  "detail-student-wise-invoices";
export const GET_ANNUAL_INVOICE_URL = "annual-invoices";
export const GET_DETAIL_ANNUAL_INVOICE_URL = "detail-annual-invoices";
export const GET_INVOICE_REVENUE_SUMMARY_URL = "invoices-revenue-summary";
export const GET_INVOICE_DASHBOARD_DATA_URL = "invoice-dashboard-data";
export const DOWNLOAD_INVOICE_PDF = "download-invoice";
export const INVOICES = "manageInvoicesSlice";

export const PAYMENT_MODE_API_URL = "payment-mode";
export const GET_ALL_PAYMENT_MODE_API_URL = "get-all-payment-mode";
export const PAYMENT_MODE = "managePaymentModeSlice";

export const PAYMENT_API_URL = "payment";
export const GET_ALL_PAYMENT_API_URL = "get-all-payment";
export const IMPORT_EFT_FILE_API_URL = "import-eft-file";
export const GET_ALL_STUDENT_PENDING_INVOICES_API_URL =
  "get-student-pending-invoices";
export const PAYMENT = "managePaymentSlice";

export const CONVERSATION_TYPES_API_URL = "conversation-types";
export const GET_ALL_CONVERSATION_TYPES_API_URL = "get-all-conversation-types";
export const CONVERSATION_TYPES = "manageConversationTypesSlice";

export const CONVERSATION_CHANNEL_API_URL = "conversation-channel";
export const GET_ALL_CONVERSATION_CHANNEL_API_URL =
  "get-all-conversation-channel";
export const CONVERSATION_CHANNEL = "manageConversationChannelSlice";

export const TEMPLATES_TYPES_API_URL = "templates-types";
export const GET_ALL_TEMPLATES_TYPES_API_URL = "get-all-templates-types";
export const TEMPLATES_TYPES = "manageTemplatesTypesSlice";

export const CONVERSATION_TEMPLATES_API_URL = "conversation-templates";
export const GET_ALL_CONVERSATION_TEMPLATES_API_URL =
  "get-all-conversation-templates";
export const CONVERSATION_TEMPLATES = "manageConversationTemplatesSlice";

export const MANAGE_SCHOOL_API_URL = "manage-school";
export const GET_ALL_MANAGE_SCHOOL_API_URL = "get-all-manage-school";
export const MANAGE_SCHOOL = "manageManageSchoolSlice";

export const USER_API_URL = "users";
export const USER_LOGIN_HISTORY_API_URL = "user-login-history";
// export const GET_ALL_USER_API_URL = 'get-all-user'
export const USER = "manageUserSlice";

export const MARITAL_STATUS_API_URL = "marital-status";
export const GET_ALL_MARITAL_STATUS_API_URL = "get-all-marital-status";
export const MARITAL_STATUS = "manageMaritalStatusSlice";

export const UPDATE_USER_STATUS_API_URL = "update-user-status";
export const AUTO_GENERATE_PASSWORD_API_URL = "auto-generate-user-password";
export const UPDATE_PASSWORD_BY_ADMIN_API_URL = "update-user-password-by-admin";
export const TAG_MACHINE_TO_USER_API_URL = "tag-machine-to-user";
export const UPDATE_CURRENT_USER_PASSWORD = "update-current-user-password";
export const UPDATE_CURRENT_USER_PIN = "update-current-user-pin";
export const UPDATE_USERS_PASSWORD = "update-users-password";
export const ADD_USER_NEW_ORGANIZATION = "add-user-new-organization";
export const GET_USER_ORGANIZATION_ROLES = "get-user-organization-roles";
export const DETACH_USER_ORGANIZATION_ROLES = "detach-user-organization-roles";
export const UPDATE_USER_PROFILE_PICTURE = "update-profile-picture";
export const REMOVE_USER_PROFILE_PICTURE = "remove-profile-picture";
export const USER_SIGNATURE = "user-signature";

export const NAME_OF_COMMON_SLICE = "commonSlice";
export const GET_GENDER = "get-genders";
export const GET_ORGANIZATION_GRADE_LEVEL = "get-organization-grade-level";

export const MEDICAL_CONDITION_TYPE_API_URL = "medical-condition-type";
export const GET_ALL_MEDICAL_CONDITION_TYPE_API_URL =
  "get-all-medical-condition-type";
export const MEDICAL_CONDITION_TYPE = "manageMedicalConditionTypeSlice";

export const TIMEZONE_CODE_API_URL = "timezone-code";
export const GET_ALL_TIMEZONE_CODE_API_URL = "get-all-timezone-code";
export const TIMEZONE_CODE = "manageTimezoneCodeSlice";
export const SAVE_GOOGLE_TOKEN = "save-google-token";

export const GET_COUNTS = "get-counts";
export const GET_ENROLLMENT_DATA = "get-enrollment-data";
export const CLEAR_NOTIFICATION = "clear-notification-data";
export const GET_MONTHLY_ATTENDANCE = "get-monthly-attendance";
export const GET_NOTIFICATION_DATA = "get-notification-data";
export const GET_STUDENT_BIRTHDAYS = "get-student-birthdays";
export const GET_CALENDAR_EVENTS = "get-calendar-events";
export const DASHBOARD = "dashboardSlice";

export const GET_ALL_CONVERSATIONS = "get-all-conversations";
export const SEND_EMAIL = "send-email";
export const ADD_CONVERSATION = "add-conversation";
export const DELETE_CONVERSATION = "delete-conversation";

export const CALENDAR_CATEGORY_API_URL = "calendar-category";
export const GET_ALL_CALENDAR_CATEGORY_API_URL = "get-all-calendar-category";
export const CALENDAR_CATEGORY = "manageCalendarCategorySlice";

export const CALENDAR_EVENT_API_URL = "calendar-event";
export const GET_ALL_CALENDAR_EVENT_API_URL = "get-all-calendar-event";
export const CALENDAR_EVENT = "manageCalendarEventSlice";
export const GET_GOOGLE_CLASSROOM_STATUS = "get-google-classroom-status";

export const INCIDENT_REPORT_API_URL = "incident-report";
export const GET_ALL_INCIDENT_REPORT_API_URL = "get-all-incident-report";
export const SAVE_PARTICIPANT_API_URL = "save-participant";
export const SAVE_INCIDENT_ACTION_API_URL = "save-incident-action";
export const GET_INCIDENT_ACTION_API_URL = "get-incident-action";
export const GET_INCIDENTS_DATA_API_URL = "get-Incidents-data";
export const SAVE_PARTICIPANT_ACTION_API_URL = "save-participant-action";
export const SAVE_OTHER_PARTICIPANT_API_URL = "save-other-participant";
export const GET_PARTICIPANT_DATA_API_URL = "get-participant-data";
export const GET_INCIDENT_DASHBOARD_DATA_API_URL =
  "get-incident-dashboard-data";
export const DELETE_PARTICIPANT_API_URL = "delete-participant";
export const INCIDENT_REPORT = "manageIncidentReportSlice";

export const COMMUNICATION_API_URL = "communication";
export const GET_ALL_COMMUNICATION_API_URL = "get-all-communication";
export const SENT_COMMUNICATION_MAIL_API_URL = "sent-communication-mail";
export const GET_COMMUNICATION_MAIL_API_URL = "get-communication-mail";
export const GET_COMMUNICATION_CONVERSATION_API_URL =
  "get-communication-conversation";
export const DELETE_COMMUNICATION_MAIL_API_URL = "delete-communication-mail";
export const COMMUNICATION = "manageCommunicationSlice";

export const BATCHES_API_URL = "batches";
export const GET_ALL_BATCHES_API_URL = "get-all-batches";
export const GET_ALL_BATCH_INVOICE_API_URL = "get-all-batch-invoice";
export const POST_BATCH_API_URL = "post-batch";
export const VIEW_STUDENT_INVOICE_API_URL = "view-student-invoice";
export const BATCHES = "manageBatchesSlice";

export const CHAT_API_URL = "chat";
export const GET_ALL_CHAT_API_URL = "get-all-chat";
export const CHAT = "manageChatSlice";

export const INCIDENT_TYPE_API_URL = "incident-type";
export const GET_ALL_INCIDENT_TYPE_API_URL = "get-all-incident-type";
export const INCIDENT_TYPE = "manageIncidentTypeSlice";

export const INCIDENT_ACTION_CODE_API_URL = "incident-action-code";
export const GET_ALL_INCIDENT_ACTION_CODE_API_URL =
  "get-all-incident-action-code";
export const INCIDENT_ACTION_CODE = "manageIncidentActionCodeSlice";

export const CONTRACT_SIGN_API_URL = "contract-sign";
export const GET_ALL_CONTRACT_SIGN_API_URL = "get-all-contract-sign";
export const DOWNLOAD_CONTRACT_PDF = "download-contract-pdf";
export const CONTRACT_SIGN = "manageContractSignSlice";

export const LOCATIONS_API_URL = "locations";
export const GET_ALL_LOCATIONS_API_URL = "get-all-locations";
export const LOCATIONS = "manageLocationsSlice";

export const PROCUREMENT_ITEM_API_URL = "procurement-item";
export const GET_ALL_PROCUREMENT_ITEM_API_URL = "get-all-procurement-item";
export const PROCUREMENT_ITEM = "manageProcurementItemSlice";

export const BUDGET_ALLOCATION_API_URL = "budget-allocation";
export const GET_ALL_BUDGET_ALLOCATION_API_URL = "get-all-budget-allocation";
export const BUDGET_ALLOCATION = "manageBudgetAllocationSlice";

export const DOCUMENT_TYPE_API_URL = "document-type";
export const GET_ALL_DOCUMENT_TYPE_API_URL = "get-all-document-type";
export const GET_ALL_DOCUMENT_CATEGORY_API_URL = "get-all-document-category";
export const DOCUMENT_TYPE = "manageDocumentTypeSlice";

export const DOCUMENT_STUDENT_API_URL = "document-student";
export const STUDENT_DOCUMENTS_API_URL = "get-student-documents";
export const GET_ALL_DOCUMENT_STUDENT_API_URL = "get-all-document-student";
export const DOCUMENT_STUDENT = "manageDocumentStudentSlice";

export const COMMENT_API_URL = "comment";
export const GET_ALL_COMMENT_API_URL = "get-all-comment";
export const COMMENT = "manageCommentSlice";

export const COMMENT_CATEGORY_API_URL = "comment-category";
export const GET_ALL_COMMENT_CATEGORY_API_URL = "get-all-comment-category";
export const COMMENT_CATEGORY = "manageCommentCategorySlice";
export const GET_CLASS_STUDENTS = "get-class-students";

export const ACCOUNTS_API_URL = "accounts";
export const GET_ALL_ACCOUNTS_API_URL = "get-all-accounts";
export const ACCOUNTS = "manageAccountsSlice";

export const STUDENT_LEDGER_API_URL = "student-ledger";
export const GET_ALL_STUDENT_LEDGER_API_URL = "get-all-student-ledger";
export const STUDENT_LEDGER = "manageStudentLedgerSlice";

export const RECEIVABLES_REPORT_API_URL = "receivables-report";
export const GET_ALL_RECEIVABLES_REPORT_API_URL = "get-all-receivables-report";
export const RECEIVABLES_REPORT = "manageReceivablesReportSlice";
export const STUDENT_RECEIVEABLE_REPORT = "student-receiveable-report";

export const LEGAL_STATUS_API_URL = "legal-status";
export const GET_ALL_LEGAL_STATUS_API_URL = "get-all-legal-status";
export const LEGAL_STATUS = "manageLegalStatusSlice";

export const TAX_API_URL = "tax";
export const GET_ALL_TAX_API_URL = "get-all-tax";
export const TAX = "manageTaxSlice";

export const TAX_TYPE_API_URL = "tax-type";
export const GET_ALL_TAX_TYPE_API_URL = "get-all-tax-type";
export const TAX_TYPE = "manageTaxTypeSlice";

export const MACHINE_API_URL = "machine";
export const GET_ALL_MACHINE_API_URL = "get-all-machine";
export const MACHINE = "manageMachineSlice";

export const ACADEMIC_REPORTS_API_URL = "academic-reports";
export const GET_ALL_ACADEMIC_REPORTS_API_URL = "get-all-academic-reports";
export const ACADEMIC_REPORTS = "manageAcademicReportsSlice";

export const CLASS_WISE_API_URL = "class-wise";
export const GET_ALL_CLASS_WISE_API_URL = "get-all-class-wise";
export const EXPORT_STUDENT_CLASS_WISE_API_URL = "export-all-class-wise-info";
export const CLASS_WISE = "manageClasswiseSlice";

export const EMPLOYEE_ATTENDANCE_API_URL = "employee-attendance";
export const GET_ALL_EMPLOYEE_ATTENDANCE_API_URL =
  "get-all-employee-attendance";
export const EMPLOYEE_ATTENDANCE = "manageEmployeeAttendanceSlice";

export const REPORT_CARD_TYPE_API_URL = "report-card-type";
export const GET_ALL_REPORT_CARD_TYPE_API_URL = "get-all-report-card-type";
export const REPORT_CARD_TYPE = "manageReportCardTypeSlice";

export const REPORT_CARD_BATCH_API_URL = "report-card-batch";
export const STUDENT_REPORT_CARD_DETAIL_API_URL = "save-student-assessment";
export const GET_ALL_REPORT_CARD_BATCH_API_URL = "get-all-report-card-batch";
export const GET_GRADE_BATCHS_API_URL = "get_grade_sub_batches";
export const GET_SUB_BATCH_SINGLE_CLASS = "get-sub-batch-single-class";
export const PRE_LOAD_DATA = "pre-load-data";
export const DOWNLOAD_REPORT_CARD_BATCH = "download-report-card-batch";
export const REPORT_CARD_BATCH = "manageReportCardBatchSlice";
export const HOLD_REPORT_CARD = "hold-report-card";
export const APPROVE_SUB_BATCH = "approve-sub-batch";
export const ROLL_BACK_APPROVE_SUB_BATCH = "roll-back-approve-sub-batch";
export const APPROVE_BATCH = "approve-batch";
export const PUBLISH_BATCH = "publish-batch";

export const SIBLING_REPORT_API_URL = "sibling-report";
export const GET_ALL_SIBLING_REPORT_API_URL = "get-all-sibling-report";
export const SIBLING_REPORT = "manageSiblingReportSlice";
export const EXPORT_SIBLING_REPORT_API_URL = "export-all-sibling-report";

export const TEACHER_WISE_API_URL = "teacher-wise";
export const GET_ALL_TEACHER_WISE_API_URL = "get-all-teacher-wise";
export const TEACHER_WISE = "manageTeacherWiseSlice";
export const EXPORT_TEACHER_WISE_REPORT_API_URL = "export-all-teacher-wise";

export const WIFI_ROUTERS_API_URL = "wifi-routers";
export const GET_ALL_WIFI_ROUTERS_API_URL = "get-all-wifi-routers";
export const WIFI_ROUTERS = "manageWifiRoutersSlice";

export const STRANDS_API_URL = 'strands'
export const GET_ALL_STRANDS_API_URL = 'get-all-strands'
export const UPDATE_STRANDS_SORT_API_URL = "update-strands-sort";
export const STRANDS = 'manageStrandsSlice'

export const TRANSPORTATION_REPORT_API_URL = 'transportation-report'
export const GET_ALL_TRANSPORTATION_REPORT_API_URL = 'get-all-transportation-report'
export const TRANSPORTATION_REPORT = 'manageTransportationReportSlice'
export const COURSE_CATEGORIES_API_URL = "course-categories";
export const GET_ALL_COURSE_CATEGORIES_API_URL = "get-all-course-categories";
export const COURSE_CATEGORIES = "manageCourseCategoriesSlice";

