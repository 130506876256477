import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import commonSlice from "./slices/commonSlice";
//_import_slice_here
    import manageTransportationReportSlice from './slices/TransportationReport/manageTransportationReportSlice';
    import manageStrandsSlice from './slices/Strands/manageStrandsSlice';
    import manageCourseCategoriesSlice from './slices/CourseCategories/manageCourseCategoriesSlice';
    import manageWifiRoutersSlice from './slices/WifiRouters/manageWifiRoutersSlice';
    import manageTeacherWiseSlice from './slices/TeacherWise/manageTeacherWiseSlice';
import manageReportCardBatchSlice from './slices/ReportCardBatch/manageReportCardBatchSlice';
import manageReportCardTypeSlice from './slices/ReportCardType/manageReportCardTypeSlice';
import manageSiblingReportSlice from './slices/SiblingReport/manageSiblingReportSlice';
import manageEmployeeAttendanceSlice from './slices/EmployeeAttendance/manageEmployeeAttendanceSlice';
import manageClasswiseSlice from './slices/ClassWise/manageClassWiseSlice';
import manageAcademicReportsSlice from './slices/AcademicReports/manageAcademicReportsSlice';
import manageAssessmentLevelSlice from './slices/AssessmentLevel/manageAssessmentLevelSlice';
import manageMachineSlice from './slices/Machine/manageMachineSlice';
import manageReportCardSlice from './slices/ReportCard/manageReportCardSlice';
import manageTaxTypeSlice from './slices/TaxType/manageTaxTypeSlice';
import manageTaxSlice from './slices/Tax/manageTaxSlice';
import manageLegalStatusSlice from './slices/LegalStatus/manageLegalStatusSlice';
import manageReceivablesReportSlice from './slices/ReceivablesReport/manageReceivablesReportSlice';
import manageStudentLedgerSlice from './slices/StudentLedger/manageStudentLedgerSlice';
import manageAccountsSlice from './slices/Accounts/manageAccountsSlice';
import manageCommentCategorySlice from './slices/CommentCategory/manageCommentCategorySlice';
import manageCommentSlice from './slices/Comment/manageCommentSlice';
import manageSystemLogsSlice from './slices/SystemLogs/manageSystemLogsSlice';
import manageDocumentStudentSlice from './slices/DocumentStudent/manageDocumentStudentSlice';
import manageDocumentTypeSlice from './slices/DocumentType/manageDocumentTypeSlice';
import manageBudgetAllocationSlice from './slices/BudgetAllocation/manageBudgetAllocationSlice';
import manageProcurementItemSlice from './slices/ProcurementItem/manageProcurementItemSlice';
import managePurchaseRequestsSlice from './slices/PurchaseRequests/managePurchaseRequestsSlice';
import manageLocationsSlice from './slices/Locations/manageLocationsSlice';
import manageStudentGradingSlice from './slices/StudentGrading/manageStudentGradingSlice';
import manageContractSignSlice from './slices/ContractSign/manageContractSignSlice';
import manageIncidentActionCodeSlice from './slices/IncidentActionCode/manageIncidentActionCodeSlice';
import manageIncidentTypeSlice from './slices/IncidentType/manageIncidentTypeSlice';
import manageChatSlice from './slices/Chat/manageChatSlice';
import manageBatchesSlice from './slices/Batches/manageBatchesSlice';
import manageCommunicationSlice from './slices/Communication/manageCommunicationSlice';
import manageIncidentReportSlice from './slices/IncidentReport/manageIncidentReportSlice';
import manageCalendarEventSlice from './slices/CalendarEvent/manageCalendarEventSlice';
import manageCalendarCategorySlice from './slices/CalendarCategory/manageCalendarCategorySlice';
import dashboardSlice from "./slices/dashboardManagement/dashboardSlice";
import attendanceSlice from "./slices/AttendanceManagement/attendanceSlice";
import assignmentSlice from "./slices/Assignments/assignmentSlice";
import importSlice from "./slices/Imports/importSlice";
import topicSlice from "./slices/Topics/topicSlice";
import manageTimezoneCodeSlice from './slices/TimezoneCode/manageTimezoneCodeSlice';
import manageMedicalConditionTypeSlice from './slices/MedicalConditionType/manageMedicalConditionTypeSlice';
import manageMaritalStatusSlice from './slices/MaritalStatus/manageMaritalStatusSlice';
import manageUserSlice from './slices/User/manageUserSlice';
import manageManageSchoolSlice from './slices/ManageSchool/manageManageSchoolSlice';
import manageConversationTemplatesSlice from './slices/ConversationTemplates/manageConversationTemplatesSlice';
import manageTemplatesTypesSlice from './slices/TemplatesTypes/manageTemplatesTypesSlice';
import manageConversationChannelSlice from './slices/ConversationChannel/manageConversationChannelSlice';
import manageConversationTypesSlice from './slices/ConversationTypes/manageConversationTypesSlice';
import managePaymentSlice from './slices/Payment/managePaymentSlice';
import managePaymentModeSlice from './slices/PaymentMode/managePaymentModeSlice';
import manageInvoicesSlice from './slices/Invoices/manageInvoicesSlice';
import manageStudentChargesSlice from './slices/StudentCharges/manageStudentChargesSlice';
import manageRecurringChargesSlice from './slices/RecurringCharges/manageRecurringChargesSlice';
import manageChargesRatesSlice from './slices/ChargesRates/manageChargesRatesSlice';
import manageChargesTypesSlice from './slices/ChargesTypes/manageChargesTypesSlice';
import manageChargesCategoriesSlice from './slices/ChargesCategories/manageChargesCategoriesSlice';
import manageSendNotificationSlice from './slices/SendNotification/manageSendNotificationSlice';
import manageManageTransportSlice from './slices/ManageTransport/manageManageTransportSlice';
import manageManageBusesSlice from './slices/ManageBuses/manageManageBusesSlice';
import manageManageRoutesSlice from './slices/ManageRoutes/manageManageRoutesSlice';
import manageLeaveRequestSlice from './slices/LeaveRequest/manageLeaveRequestSlice';
import manageAbsentReasonSlice from './slices/AbsentReason/manageAbsentReasonSlice';
import manageAttendanceReportSlice from './slices/AttendanceReport/manageAttendanceReportSlice';
import manageAttendanceModificationSlice from './slices/AttendanceModification/manageAttendanceModificationSlice';
import manageManageClassSlice from './slices/ManageClass/manageManageClassSlice';
import manageClassSlice from './slices/Class/manageClassSlice';
import manageCourseSlice from './slices/Course/manageCourseSlice';
import manageSchoolTermSlice from './slices/SchoolTerm/manageSchoolTermSlice';
import manageGradeBookCategorySlice from './slices/GradeBookCategory/manageGradeBookCategorySlice';
import manageGradeSlice from './slices/Grade/manageGradeSlice';
import studentGradeBookSlice from './slices/Grade/studentGradeBookSlice';
import manageSchoolRoomSlice from './slices/SchoolRoom/manageSchoolRoomSlice';
import manageStudentSlice from './slices/Student/manageStudentSlice';
import manageManageGuardianSlice from './slices/ManageGuardian/manageManageGuardianSlice';
import manageIdentificationTypeSlice from './slices/IdentificationType/manageIdentificationTypeSlice';
import manageEnrollmentStatusSlice from './slices/EnrollmentStatus/manageEnrollmentStatusSlice';
import manageSalutationSlice from './slices/Salutation/manageSalutationSlice';
import manageAllergiesSlice from './slices/Allergies/manageAllergiesSlice';
import manageNationalitySlice from './slices/Nationality/manageNationalitySlice';
import manageAddressTypeSlice from './slices/AddressType/manageAddressTypeSlice';
// import manageThemeSettingSlice from './slices/ThemeSetting/manageThemeSettingSlice';
import manageProvinceSlice from './slices/Province/manageProvinceSlice';
import manageRelationshipSlice from './slices/Relationship/manageRelationshipSlice';
import manageSchoolYearSlice from './slices/SchoolYear/manageSchoolYearSlice';
import manageGradeLevelSlice from './slices/GradeLevel/manageGradeLevelSlice';
import manageSchoolCategorySlice from './slices/SchoolCategory/manageSchoolCategorySlice';
import manageTeacherRoleSlice from './slices/TeacherRole/manageTeacherRoleSlice';
import manageCitySlice from './slices/City/manageCitySlice';
import manageCountrySlice from './slices/Country/manageCountrySlice';
import manageOrganizationTypeSlice from './slices/OrganizationType/manageOrganizationTypeSlice';
import manageSchoolTypeSlice from './slices/SchoolType/manageSchoolTypeSlice';
import manageManageTeacherSlice from './slices/ManageTeacher/manageManageTeacherSlice';
import manageRolePermissionSlice from './slices/RolePermission/manageRolePermissionSlice';
import manageOrganizationSlice from './slices/Organization/manageOrganizationSlice';
import manageSetupOrganizationSlice from "./slices/SetupOrganization/manageSetupOrganizationSlice";
import manageManageUserSlice from "./slices/ManageUser/manageManageUserSlice";

import manageModuleSlice from "./slices/ManageModuleSlice/manageModuleSlice";
import manageModuleGroupSlice from "./slices/ManageModuleGroupSlice/manageModuleGroupSlice";
import managePermissionSlice from "./slices/ManagePermissionSlice/managePermissionSlice";
import manageRoleSlice from "./slices/ManageRoleSlice/manageRoleSlice";
import manageFluidSearchSlice from "./slices/FluidSearchSlice/manageFluidSearchSlice";

const rootReducer = (asyncReducers) => (state, action) => {
        const combinedReducer = combineReducers({
                theme,
                auth,
                commonSlice,
                manageModuleSlice,
                managePermissionSlice,
                manageModuleGroupSlice,
                manageRoleSlice,
                attendanceSlice,
                assignmentSlice,
                importSlice,
                topicSlice,
                //_Register_slice_after
    manageTransportationReportSlice,
    manageStrandsSlice,
    manageCourseCategoriesSlice,
    manageWifiRoutersSlice,
    manageTeacherWiseSlice,
                manageReportCardBatchSlice,
                manageReportCardTypeSlice,
                manageSiblingReportSlice,
                manageEmployeeAttendanceSlice,
                manageClasswiseSlice,
                manageAcademicReportsSlice,
                manageAssessmentLevelSlice,
                manageMachineSlice,
                manageReportCardSlice,
                manageTaxTypeSlice,
                manageTaxSlice,
                manageLegalStatusSlice,
                manageReceivablesReportSlice,
                manageStudentLedgerSlice,
                manageAccountsSlice,
                manageCommentCategorySlice,
                manageCommentSlice,
                manageSystemLogsSlice,
                manageDocumentStudentSlice,
                manageDocumentTypeSlice,
                manageBudgetAllocationSlice,
                manageProcurementItemSlice,

                managePurchaseRequestsSlice,
                manageLocationsSlice,
                manageStudentGradingSlice,
                manageContractSignSlice,
                manageIncidentActionCodeSlice,
                manageIncidentTypeSlice,
                manageChatSlice,
                manageBatchesSlice,
                manageCommunicationSlice,
                manageIncidentReportSlice,
                manageCalendarEventSlice,
                manageCalendarCategorySlice,
                dashboardSlice,
                manageTimezoneCodeSlice,
                manageMedicalConditionTypeSlice,
                manageMaritalStatusSlice,
                manageUserSlice,
                manageManageSchoolSlice,
                manageConversationTemplatesSlice,
                manageTemplatesTypesSlice,
                manageConversationChannelSlice,
                manageConversationTypesSlice,
                managePaymentSlice,
                managePaymentModeSlice,
                manageInvoicesSlice,
                manageStudentChargesSlice,
                manageRecurringChargesSlice,
                manageChargesRatesSlice,
                manageChargesTypesSlice,
                manageChargesCategoriesSlice,
                manageSendNotificationSlice,
                manageManageTransportSlice,
                manageManageBusesSlice,
                manageManageRoutesSlice,
                manageLeaveRequestSlice,
                manageAbsentReasonSlice,
                manageAttendanceReportSlice,
                manageAttendanceModificationSlice,
                manageManageClassSlice,
                manageClassSlice,
                manageCourseSlice,
                manageSchoolTermSlice,
                manageGradeBookCategorySlice,
                manageGradeSlice,
                studentGradeBookSlice,
                manageSchoolRoomSlice,
                manageStudentSlice,
                manageManageGuardianSlice,
                manageIdentificationTypeSlice,
                manageEnrollmentStatusSlice,
                manageSalutationSlice,
                manageAllergiesSlice,
                manageNationalitySlice,
                manageAddressTypeSlice,
                // manageThemeSettingSlice,
                manageProvinceSlice,
                manageRelationshipSlice,
                manageSchoolYearSlice,
                manageGradeLevelSlice,
                manageSchoolCategorySlice,
                manageTeacherRoleSlice,
                manageCitySlice,
                manageCountrySlice,
                manageOrganizationTypeSlice,
                manageSchoolTypeSlice,
                manageManageTeacherSlice,
                manageRolePermissionSlice,
                manageOrganizationSlice,
                manageSetupOrganizationSlice,
                manageManageUserSlice,

                manageFluidSearchSlice,
                ...asyncReducers,
        });
        return combinedReducer(state, action);
};

export default rootReducer;
