import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STRANDS, STRANDS_API_URL, GET_ALL_STRANDS_API_URL, UPDATE_STRANDS_SORT_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  StrandsErrors: {},
  permission: permission?.strands ?? [],
  StrandsShowMessage: null,
  StrandsResult: [],
  StrandsButtonSpinner: false,
  StrandsTableLoading: true,
  StrandsAddDrawer: false,
  StrandsEditData: [],
  StrandsButtonAndModelLabel: setLocale('strands.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewStrandsData: [],
  ViewStrandsLoader: true,
  DrawerStatus: 0,
};

export const createStrands = createAsyncThunk(
  "createStrands",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, STRANDS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStrands = createAsyncThunk(
  "getStrands",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_STRANDS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewStrands = createAsyncThunk(
  "viewStrands",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, STRANDS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteStrands = createAsyncThunk(
  "deleteStrands",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, STRANDS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const updateStrandsSort = createAsyncThunk(
  "updateStrandsSort",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, UPDATE_STRANDS_SORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);


export const manageStrandsSlice = createSlice({
  name: STRANDS,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.StrandsErrors = {};
    },
    StrandsAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.StrandsButtonSpinner = false;
      }
      state.StrandsAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.StrandsEditData = [];
      state.StrandsButtonAndModelLabel = setLocale('strands.add');
    },
    StrandsEditWithDrawerStatus: (state, action) => {
      state.StrandsAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.StrandsEditData = action.payload.data;
      state.StrandsButtonAndModelLabel = setLocale('strands.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStrands.pending, (state, action) => {
        state.DrawerStatus = 0
        state.StrandsButtonSpinner = true;
      }).addCase(createStrands.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.StrandsButtonSpinner = false;
        state.StrandsErrors = {}
      }).addCase(createStrands.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.StrandsShowMessage = true;
        state.StrandsButtonSpinner = false;
        state.StrandsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getStrands.pending, (state, action) => {
        state.StrandsButtonSpinner = true;
        state.StrandsTableLoading = true;
      }).addCase(getStrands.fulfilled, (state, action) => {
        state.StrandsButtonSpinner = false;
        state.StrandsTableLoading = false;
        state.StrandsResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getStrands.rejected, (state, action) => {
        state.StrandsShowMessage = true; // Set the showMessage flag to display the errors
        state.StrandsButtonSpinner = false;
        state.StrandsTableLoading = false;
        state.StrandsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewStrands.pending, (state, action) => {
        state.ViewStrandsLoader = true;
        state.ViewStrandsData = [];
      }).addCase(viewStrands.fulfilled, (state, action) => {
        state.ViewStrandsLoader = false;
        state.ViewStrandsData = action.payload;
      }).addCase(viewStrands.rejected, (state, action) => {
        state.ViewStrandsLoader = false;
        state.ViewStrandsData = [];
      })
      .addCase(deleteStrands.pending, (state, action) => {
        state.StrandsTableLoading = true;
      }).addCase(deleteStrands.fulfilled, (state, action) => {
        state.StrandsTableLoading = false;
      }).addCase(deleteStrands.rejected, (state, action) => {
        state.StrandsTableLoading = false;
      })
      .addCase(updateStrandsSort.pending, (state, action) => {
        state.StrandsTableLoading = true;
      }).addCase(updateStrandsSort.fulfilled, (state, action) => {
        state.StrandsTableLoading = false;
      }).addCase(updateStrandsSort.rejected, (state, action) => {
        state.StrandsTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, StrandsAddDrawerStatus, StrandsEditWithDrawerStatus, updateSortFilters } = manageStrandsSlice.actions;

export default manageStrandsSlice.reducer;
